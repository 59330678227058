/** @format */

import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import queryString from "query-string";
import styles from "./Register.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import "../../styling/common.css";
import register from "../../img/register.webp";
import hands from "../../img/hands.svg";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import AOS from "aos";
import "aos/dist/aos.css";
import Cross from "../../img/IconComponents/Cross";
import Dropdown from "../../components/Dropdown/Dropdown";
import cn from "classnames";
import Triangle from "../../img/IconComponents/Triangle";
import { useParams } from "react-router-dom";
import moment from "moment";
import { FRONT_URL } from "../../http";
import mokupToken from "../../img/mokupToken.webp";
import {
  checkFirstName,
  checkLastName,
  checkEmail,
  checkPhone,
  checkReferalId,
  checkPass,
  checkPasswordAgain,
  checkAge,
  checkPrivacy,
  checkCity,
  checkStreet,
  checkDoor,
  checkPost,
  checkCountry,
  checkEmailAgain,
  checkDOB,
  checkCompanyName,
  checkYear,
} from "../../helpers/validation";
import { Context } from "../../index";
import { useObserver } from "mobx-react-lite";
import postRequest from "../../helpers/postRequest";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import PhoneCountrySelect from "../../components/PhoneCountrySelect/PhoneCountrySelect";
import confirm from "../../img/confirm.svg";
import { useTranslation } from "react-i18next";
import Radio from "../../components/Radio/Radio";
import ModalRegCompleted from "../../components/ModalRegCompleted/ModalRegCompleted";
import getRequest from "../../helpers/getRequest";
import { UsedCodeModal } from "./Components/UsedCodeModal/UsedCodeModal";
import axios from "axios";
import { API_URL } from "../../http";
import { getAllCountries } from "../../helpers/getCountries";
const Register = ({
  currLang,
  setCurrLang,
  roleRegisterProp = false,
  inviteCodeProp = false,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailAgain, setEmailAgain] = useState("");
  const [phone, setPhone] = useState("");
  const [inviteCode, setInviteCode] = useState(
    inviteCodeProp
      ? inviteCodeProp
      : localStorage.getItem("referralCode") ||
          localStorage.getItem("invite_code") ||
          ""
  );
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [inviteCodeClient, setInviteCodeClient] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorEmailAgain, setErrorEmailAgain] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [referalId, setReferalId] = useState("");
  const [mlmCode, setMlmCode] = useState(null);
  const [errorReferalId, setErrorReferalId] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [errorPasswordAgain, setErrorPasswordAgain] = useState("");
  const [age, setAge] = useState(false);
  const [checkSale, setCheckSale] = useState(false);
  const [checkHeadOfSale, setCheckHeadOfSale] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [errorAge, setErrorAge] = useState("");
  const [errorSaleCheck, setErrorSaleCheck] = useState("");
  const [errorHeadOfSalesCheck, setErrorHeadOfSalesCheck] = useState("");
  const [errorPrivacy, setErrorPrivacy] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [serversError, setServersError] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [emailInvite, setEmailInvite] = useState("");
  const [errorEmailInvite, setErrorEmailInvite] = useState("");
  const [isAllowSubmitCode, setIsAllowSubmitCode] = useState(false);
  const [isModalRefId, setIsModalRefId] = useState(false);
  const [country, setCountry] = useState("Germany");
  const [isEmailCheckRef, setIsEmailCheckRef] = useState(false);
  const [isMale, setIsMale] = useState(true);
  const [emailUsedError, setEmailUsedError] = useState(false);
  const [dob, setDob] = useState();
  const [dobError, setDobError] = useState("");
  const [city, setCity] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [street, setStreet] = useState("");
  const [errorStreet, setErrorStreet] = useState("");
  const [appartment, setApartment] = useState("");
  const [errorHouseNumber, setErrorHouseNumber] = useState("");
  const [postCode, setPostCode] = useState("");
  const [errorPostcode, setErrorPostcode] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [companyName, setCompanyName] = useState(null);
  const [errorCompanyName, setErrorCompanyName] = useState("");
  const [saleRegModal, setSaleRegModal] = useState(false);
  const [roleRegister, setRoleRegister] = useState(
    roleRegisterProp
      ? roleRegisterProp
      : localStorage.getItem("role")
      ? localStorage.getItem("role")
      : "client"
  );
  const [saleLvl, setSaleLvl] = useState(localStorage.getItem("level"));
  const [isSaleZeroLvl, setIsSaleZeroLvl] = useState(false);

  const { t } = useTranslation();
  const { sale_id } = useParams();

  const [currentLang, setCurrentLang] = useState({
    name: t("Germany"),
    code: 'DE',
    phoneCode: '49'
  });
  const [countryCode, setCountryCode] = useState("+49");

  const [dayDob, setDayDob] = useState("");
  const [monthDob, setMonthDob] = useState(null);
  const [yearDob, setYearDob] = useState("");
  const [totalDob, setTotalDob] = useState();

  const [isValidCode, setIsValidCode] = useState(true);
  const [disabledReferalId, setDisabledReferalId] = useState(false);

  const { store } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const renderCountRef = useRef(0);

  useEffect(() => {
    // localStorage.setItem("role", "sale");

    if (location.pathname.includes("register/ref")) {
      changeReferral();

      localStorage.setItem("role", "client");

      const registrationIds = location.pathname.split("register/");
      if (registrationIds.length > 1) {
        localStorage.setItem("ref", registrationIds[1]);
        setReferalId(registrationIds[1]);
        setDisabledReferalId(true);
        localStorage.removeItem("intern");
      }
    } else {
      const referralData = localStorage.getItem("ref");
      if (referralData) {
        const mlmCode = referralData[1];
        const refCode = localStorage.getItem("ref");

        setMlmCode(mlmCode);
        setReferalId(refCode);
      }
    }
    const {
      invite_code,
      referralCode,
      level,
      c: isCalculatorShown,
    } = queryString.parse(location?.search);
    if (level) {
      setRoleRegister("sale");
      localStorage.setItem("role", "sale");
    }
    if (localStorage.getItem("ref")) {
      setDisabledReferalId(true);
    }
    if (localStorage.getItem("invite_code")) {
      setInviteCode(localStorage.getItem("invite_code"));
      setDisabledReferalId(true);
    }
    if (localStorage.getItem("referralCode")) {
      setInviteCode(localStorage.getItem("referralCode"));
      setDisabledReferalId(true);
    }
    if (location?.search.length) {
      if (isCalculatorShown) {
        localStorage.setItem("isCalcShown", true);
      } else {
        localStorage.removeItem("isCalcShown");
      }

      if (invite_code) {
        changeReferral();

        setInviteCode(invite_code);
        setReferalId(null);

        localStorage.setItem("invite_code", invite_code);
        localStorage.setItem("level", 0);
      }

      if (referralCode) {
        changeReferral();

        setInviteCode(referralCode);
        setReferalId(null);
        localStorage.setItem("referralCode", referralCode);

        // setRoleRegister("sale");
      }

      if (level) {
        setSaleLvl(level);
        localStorage.setItem("level", level);
        setRoleRegister("sale");
        localStorage.setItem("role", "sale");
      } else {
        setIsSaleZeroLvl(true);
        setRoleRegister("sale");
        if (level) {
          localStorage.setItem("level", level);
        }

        localStorage.setItem("role", "sale");
      }

      if (inviteCode) {
        setErrorSaleCheck("");
        setCheckSale(true);
        setRoleRegister("sale");
        renderCountRef.current += 1;
        if (renderCountRef.current === 1) {
          getRequest(`v1/auth/check-invite/${invite_code}`)
            .then((response) => response)
            .catch((e) => {
              console.log(e);
              if (e.response.status === 409 || e.response.status === 404) {
                setIsValidCode(false);
              }
            });
        }
      } else {
        setErrorSaleCheck("");
        setCheckSale(true);
        if (roleRegister === "client") {
          setCheckHeadOfSale(true);
          setErrorHeadOfSalesCheck("");
        }
      }
    }

    
    console.log(location.pathname);
    if (location.pathname !== "/register") {
      navigate("/");
    }
  }, []);

  const changeReferral = () => {
    setReferalId(null);
    setInviteCode(null);
    setRoleRegister(null);
    setSaleLvl(null);

    localStorage.removeItem("intern");
    localStorage.removeItem("ref");
    localStorage.removeItem("role");
    localStorage.removeItem("level");

    localStorage.removeItem("referralCode");
    localStorage.removeItem("invite_code");
  };

  const handleDobChange = (event, setter) => {
    const value = event.target.value;
    const regex = /^\d*$/;

    if (regex.test(value)) {
      setter(value);
    }
  };

  // const handleDayChange = (event) => {
  //   const value = event.target.value;
  //   const regex = /^\d+$/;

  //   if (value === '' || regex.test(value)) {
  //     const numericValue = value === '' ? null : parseInt(value, 10);
  //     if (numericValue === null || (numericValue >= 1 && numericValue <= 31)) {
  //       setDayDob(numericValue);
  //     }
  //   }
  // };

  const handleDayChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*$/;

    if (regex.test(value) && value.length <= 2 && value <= 31) {
      setDayDob(value);
    }

    // if (regex.test(value)) {
    //   setDayDob(value);
    // }

    // if (value === '' || regex.test(value)) {
    //   const numericValue = value === '' ? null : parseInt(value, 10);
    //   if (numericValue === null || (numericValue >= 1 && numericValue <= 31)) {
    //     setDayDob(numericValue);
    //   }
    // }
  };

  const handleMonthChange = (event) => {
    // const value = event.target.value;
    // const regex = /^\d+$/;

    // if (value === '' || regex.test(value)) {
    //   const numericValue = value === '' ? null : parseInt(value, 10);
    //   if (numericValue === null || (numericValue >= 1 && numericValue <= 12)) {
    //     setMonthDob(numericValue);
    //   }
    // }

    const value = event.target.value;
    const regex = /^\d*$/;

    if (regex.test(value) && value.length <= 2 && value <= 12) {
      setMonthDob(value);
    }
  };

  const handleYearChange = (event) => {
    const value = event.target.value;
    const regex = /^\d{0,4}$/; // Регулярное выражение для проверки на целые числа с максимальной длиной 4 символа

    if (regex.test(value)) {
      setYearDob(value);
      // if(value > 2005){
      //   setDobError("Error")
      // }else{
      //   setDobError("")
      // }
    }
  };

  const handleInputBlur = (e, state, setter) => {
    if (state?.length < 2 && state?.length >= 1) {
      setter("0" + state);
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  //ыыыыыы
  useEffect(() => {
    if (sale_id && !location?.pathname?.includes("/tip")) {
      setSaleRegModal(true);
      store.verifyUser(sale_id);
    } else {
      setSaleRegModal(false);
    }
  }, [sale_id]);

  const {
    errorRegistraion,
    showPageCheckPost,
    isLoadSignUp,
    registerResponse,
  } = useObserver(() => ({
    errorRegistraion: store.errorRegistraion,
    showPageCheckPost: store.showPageCheckPost,
    isLoadSignUp: store.isLoadSignUp,
    registerResponse: store.registerResponse,
  }));

  const checkFields = () => {
    const isError = [];
    checkFirstName(
      firstName,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorFirstName(value);
      }
    );
    checkLastName(
      lastName,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorLastName(value);
      }
    );
    checkEmail(
      email,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorEmail(value);
      }
    );
    checkPhone(
      `${countryCode}${phone}`,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPhone(value);
      }
    );
    checkPass(
      password,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPassword(value);
      }
    );

    checkPasswordAgain(
      passwordAgain,
      password,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPasswordAgain(value);
      }
    );

    checkEmailAgain(
      emailAgain,
      email,
      () => {},
      (value) => {
        console.log(value);
        isError.push(Boolean(value));
        setErrorEmailAgain(value);
      }
    );

    checkAge(
      age,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorAge(value);
      }
    );

    checkAge(
      privacy,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPrivacy(value);
      }
    );

    // checkAge(
    //   checkSale,
    //   () => {},
    //   (value) => {
    //     isError.push(Boolean(value));
    //     setErrorSaleCheck(value);
    //   }
    // );

    // checkAge(
    //   checkHeadOfSale,
    //   () => {},
    //   (value) => {
    //     isError.push(Boolean(value));
    //     setErrorHeadOfSalesCheck(value);
    //   }
    // );

    checkCity(
      city,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorCity(value);
      }
    );
    checkStreet(
      street,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorStreet(value);
      }
    );
    checkDoor(
      appartment,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorHouseNumber(value);
      }
    );
    checkPost(
      postCode,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorPostcode(value);
      }
    );
    checkCountry(
      country,
      () => {},
      (value) => {
        isError.push(Boolean(value));
        setErrorCountry(value);
      }
    );
    // checkDOB(
    //   dob,
    //   () => {},
    //   (value) => {
    //     isError.push(Boolean(value))
    //     setDobError(value)
    //   },
    // )

    // checkYear(
    //   yearDob,
    //   () => {},
    //   (value) => {
    //     isError.push(Boolean(value))
    //     setDobError(value)
    //   },
    // )

    if (
      !!!dayDob ||
      dayDob?.length !== 2 ||
      !!!monthDob ||
      monthDob?.length !== 2 ||
      !!!yearDob ||
      yearDob?.length !== 4
    ) {
      if (localStorage.getItem("i18n") != "EN") {
        setDobError("Geburtsdatum eingeben");
      } else {
        setDobError("Enter date of birth");
      }
      return;
    } else {
      setDobError("");
    }

    let totaldob = `${yearDob}-${monthDob}-${dayDob}`;

    const dateOfBirth = moment(totaldob);
    const today = moment();
    const ageCheck = today.diff(dateOfBirth, "years");

    if (ageCheck >= 18 && ageCheck < 100) {
      setDobError("");
    } else {
      if (localStorage.getItem("i18n") != "EN") {
        setDobError(
          "Sie müssen über 18 Jahre alt sein, um sich auf der Plattform zu registrieren"
        );
      } else {
        setDobError(
          "You must be over 18 years old to register on the platform"
        );
      }
    }

    // const age = today.diff(totaldob, 'years');
    if (!localStorage.getItem("intern")) {
      checkReferalId(
        referalId,
        inviteCode,
        () => {},
        (value) => {
          isError.push(Boolean(value));
          console.log(value);
          setErrorReferalId(value);
        }
      );
    }

    return isError.some((el) => el);
  };

  const signUpHandler = () => {
    console.log("inviteCodeClient\n", inviteCodeClient)
    console.log("referalId\n", referalId)

    console.log(referalId)
    if(!inviteCodeClient.split('/')[1] && !referalId.split('/')[1] && !localStorage.getItem("intern")){
      setErrorReferalId('Please provide referral ID')
      return;
    } 
    if (checkFields()) return;
    if (dobError?.length) return;
    store.signup(
      firstName,
      lastName,
      email,
      password,
      passwordAgain,
      `${countryCode}${phone}`,
      inviteCode?.split("-") && inviteCode.split("-").length > 2
        ? inviteCode
        : undefined,
      inviteCode?.split("-") && inviteCode.split("-").length === 2
        ? inviteCode
        : undefined,
      localStorage.getItem("i18n").toLowerCase(),
      isMale,
      setErrorReferalId,
      street || null,
      appartment || null,
      postCode || null,
      city || null,
      country || null,
      totalDob,
      companyName,
      null,
      localStorage.getItem("intern")
        ? undefined
        : (inviteCodeClient || referalId).split("/")[1] &&
          isNaN((inviteCodeClient || referalId).split("/")[1])
        ? undefined
        : (inviteCodeClient || referalId).split("/")[1]
        ? +(inviteCodeClient || referalId).split("/")[1]
        : undefined,
      localStorage.getItem("intern")
        ? localStorage.getItem("intern")
        : undefined,
      localStorage.getItem("intern")
        ? undefined
        : (inviteCodeClient || referalId).split("/")[1] &&
          isNaN((inviteCodeClient || referalId).split("/")[1])
        ? inviteCodeClient || referalId
        : undefined
    );
  };

  useEffect(() => {
    if (registerResponse.data && registerResponse.data.user_role === "SALE") {
      navigate("/check-contract", {
        state: {
          email: registerResponse.data.email,
        },
      });
    }
  }, [registerResponse]);

  const sendRequestToReferalID = () => {
    setLoader(true);
    postRequest(
      "v1/user/email/used",
      { email: emailInvite },
      "application/json",
      localStorage.getItem("i18n")?.toLowerCase()
    )
      .then((response) => {
        setEmailUsedError(response.data.email);
        if (!response.data.email) {
          postRequest(
            "v1/referrals/want",
            { email: emailInvite },
            "application/json",
            localStorage.getItem("i18n")?.toLowerCase()
          )
            .then((response) => {
              setIsModalRefId(false);
              setIsEmailCheckRef(true);
              setLoader(false);
            })
            .catch((error) => {
              setLoader(false);
            });
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (errorRegistraion.length) {
      setServersError(errorRegistraion);
      if (errorRegistraion[0] == "You should provide special code") {
        setServersError([]);
        setErrorReferalId("Please provide referral ID");
      } else {
        setErrorEmail(errorRegistraion[0]);
      }
    } else {
      setServersError([]);
    }
  }, [errorRegistraion]);

  useEffect(() => {
    if (isLoadSignUp) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isLoadSignUp]);

  useEffect(() => {
    if (showPageCheckPost === true) {
      setIsOpenModal(true);
    }
  }, [showPageCheckPost]);

  useEffect(() => {
    if (
      !location.pathname.includes("ref") &&
      !location.pathname.includes("referral") &&
      inviteCode &&
      inviteCode.length
    ) {
      setReferalId("");
      localStorage.removeItem("ref");
    }
    if (
      location.pathname.includes("ref") ||
      location.pathname.includes("referral")
    ) {
      localStorage.removeItem("intern");
      setInviteCode("");
      localStorage.removeItem("role");
    }
  }, [inviteCode, location.pathname]);

  const dependencies = [
    firstName,
    errorFirstName,
    lastName,
    errorLastName,
    email,
    errorEmail,
    password,
    errorPassword,
    age,
    privacy,
    errorAge,
    errorPrivacy,
    phone,
    errorPhone,
  ];

  useEffect(() => {
    if (
      firstName !== "" &&
      !errorFirstName.length &&
      lastName !== "" &&
      !errorLastName.length &&
      email !== "" &&
      !errorEmail.length &&
      password !== "" &&
      !errorPassword.length &&
      age &&
      !errorAge.length &&
      privacy &&
      !errorPrivacy.length
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, dependencies);

  useEffect(() => {
    if (emailInvite.length && !errorEmailInvite.length) {
      setIsAllowSubmitCode(true);
    } else {
      setIsAllowSubmitCode(false);
    }
  }, [emailInvite, errorEmailInvite]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (dayDob > 0 && monthDob > 0 && yearDob > 0 && yearDob.length > 3) {
      const dob = `${dayDob}.${monthDob}.${yearDob}`;

      const minDate = moment().subtract(18, "years"); // Дата, предшествующая 18 годам от текущей даты
      const maxDate = moment().subtract(100, "years");
      const formattedInputDate = moment(dob, "DD.MM.YYYY"); // Преобразуем введенную дату в объект Moment
      const isValid =
        formattedInputDate.isBefore(minDate) &&
        !formattedInputDate.isBefore(maxDate);

      if (isValid == false) {
        if (localStorage.getItem("i18n") != "EN") {
          setDobError(
            "Sie müssen über 18 Jahre alt sein, um sich auf der Plattform zu registrieren"
          );
        } else {
          setDobError(
            "You must be over 18 years old to register on the platform"
          );
        }
      } else {
        setDobError("");
      }

      setTotalDob(moment(dob, "DD.MM.YYYY").format("YYYY-MM-DD"));
    }
  }, [dayDob, monthDob, yearDob]);

  return (
    <>
      {isOpenModal && navigate("/check-email")}
      {loader ? <Loader /> : null}
      <HeaderCommon currentLang={currLang} setCurrentLang={setCurrLang} />
      <section className={styles.register}>
        <div className="container">
          <div className={styles.register__wrapper}>
            <div className={styles.register__left}>
              <h2 className={styles.register__title}>
                {saleLvl ? t("Register as a sale ") : t("Create an account")}
              </h2>

              {roleRegister != "sale" && roleRegister != "hs" ? (
                <div className={styles.register__info}>
                  <div>{t("Already have an account?")}</div>
                  <Link to="/login">{t("Log in")}</Link>
                </div>
              ) : null}
              <form className={styles.register__form}>
                <div className={styles.register__form_wrapper}>
                  <Input
                    label={t("First name")}
                    type="text"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("First name")}`}
                    value={firstName}
                    onChange={(e) =>
                      checkFirstName(
                        e.target.value,
                        setFirstName,
                        setErrorFirstName
                      )
                    }
                    error={errorFirstName}
                  />
                  <Input
                    label={t("Last name")}
                    type="text"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("Last name")}`}
                    value={lastName}
                    onChange={(e) =>
                      checkLastName(
                        e.target.value,
                        setLastName,
                        setErrorLastName
                      )
                    }
                    error={errorLastName}
                  />
                </div>
                <div
                  className={cn(
                    styles.register__form_wrapper,
                    styles.register__form_wrapper_data
                  )}
                >
                  <div className={styles.register__form_wrapper__gender}>
                    <span>{t("Select your gender")}</span>
                    <div>
                      <Radio
                        text={"Male"}
                        value={isMale}
                        onChange={() => setIsMale((prev) => !prev)}
                      />
                      <Radio
                        text={"Female"}
                        value={!isMale}
                        onChange={() => setIsMale((prev) => !prev)}
                      />
                    </div>
                  </div>
                  <div className={styles.register__form_dob}>
                    <div className={styles.register__dob_label}>
                      {t("Date of birth")}
                    </div>
                    <div
                      className={cn(
                        styles.register__dob,
                        dobError?.length ? styles.register__dob_error : ""
                      )}
                    >
                      <input
                        type="number"
                        placeholder="DD"
                        className={styles.register__form_dob_input}
                        value={dayDob}
                        maxLength="2"
                        onChange={(e) => handleDayChange(e)}
                        onBlur={(e) => handleInputBlur(e, dayDob, setDayDob)}
                      />
                      <input
                        type="text"
                        placeholder="MM"
                        className={styles.register__form_dob_input}
                        value={monthDob}
                        maxLength="2"
                        onChange={(e) => handleMonthChange(e)}
                        onBlur={(e) =>
                          handleInputBlur(e, monthDob, setMonthDob)
                        }
                      />
                      <input
                        type="text"
                        placeholder="YYYY"
                        className={styles.register__form_dob_input}
                        value={yearDob !== null ? yearDob : ""}
                        onChange={(e) => handleYearChange(e)}
                        // onChange={checkYear(yearDob, handleYearChange, setDobError)}
                      />
                    </div>
                    {dobError ? (
                      <div className={styles.dob_error}>
                        <Triangle />
                        <div>{dobError}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {inviteCode && inviteCode.length && (
                  <div className={styles.register__form_full_wrapper}>
                    <Input
                      label={t("Company name")}
                      type="text"
                      placeholder={`${
                        localStorage.getItem("i18n") === "DE" ? "Ihre" : ""
                      } ${t("Company name")}`}
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      error={errorCompanyName}
                    />
                  </div>
                )}

                <div className={styles.register__form_wrapper}>
                  <Input
                    label={t("Street")}
                    type="text"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("Street")}`}
                    value={street}
                    onChange={(e) =>
                      checkStreet(e.target.value, setStreet, setErrorStreet)
                    }
                    error={errorStreet}
                  />
                  <Input
                    label={t("House number")}
                    type="text"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("House number")}`}
                    value={appartment}
                    onChange={(e) =>
                      checkDoor(
                        e.target.value,
                        setApartment,
                        setErrorHouseNumber
                      )
                    }
                    error={errorHouseNumber}
                  />
                </div>
                <div className={styles.register__form_wrapper}>
                  <Input
                    label={t("Postcode")}
                    type="text"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("Postcode")}`}
                    onChange={(e) =>
                      checkPost(e.target.value, setPostCode, setErrorPostcode)
                    }
                    error={errorPostcode}
                    value={postCode}
                  />
                  <Input
                    label={t("City")}
                    type="text"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("City")}`}
                    value={city}
                    onChange={(e) =>
                      checkCity(e.target.value, setCity, setErrorCity)
                    }
                    error={errorCity}
                  />
                </div>
                <div
                  className={cn(
                    styles.register__form_wrapper,
                    styles.register__form_wrapper_data
                  )}
                >
                  <div className={styles.register__country}>
                    <Dropdown
                      value={country}
                      onChange={(country) =>{
                        checkCountry(country, setCountry, setErrorCountry)
                        setCurrentLang(getAllCountries().find(c => c.name === country))
                        console.log(getAllCountries().find(c => c.name === country))
                        setCountryCode(`+${getAllCountries().find(c => c.name === country).phoneCode}`) 
                      }
                      }
                      error={errorCountry}
                      greenBorder={true}
                    />
                  </div>
                  <div className={styles.register__form_wrapper__phone}>
                    <div className={styles.register__form_wrapper__country}>
                      <PhoneCountrySelect
                        currentLang={currentLang} // if country selected select country code, if not select country code based on currentLang, but should be possibility to change country code
                        setCurrentLang={setCurrentLang}
                        setCountryCode={setCountryCode}
                      />
                    </div>
                    <Input
                      label={t("Phone")}
                      type="text"
                      name={t("phone")}
                      placeholder={countryCode}
                      value={`${countryCode}${phone}`}
                      onChange={(e) =>
                        checkPhone(
                          e.target.value,
                          (value) => setPhone(value.slice(countryCode.length)),
                          setErrorPhone
                        )
                      }
                      error={errorPhone}
                    />
                  </div>
                  {/* <div
                    className={cn(
                      styles.register__form_dob,
                      dobError ? styles.register__form_dob_error : '',
                    )}
                  >
                    <div className={styles.register__form_dob_label}>
                      {t('Date of birth')}
                    </div>
                    <DatePicker
                      value={dob}
                      datePickAction={(dob) =>
                        checkDOB(dob, setDob, setDobError)
                      }
                      placeholder={`${
                        localStorage.getItem('i18n') == 'DE' ? 'Ihr' : ''
                      } ${t('Date of birth')}`}
                    />
                    {dobError ? (
                      <div className={styles.dob_error}>
                        <Triangle />
                        <div>{dobError}</div>
                      </div>
                    ) : null}
                  </div> */}
                </div>

                <div className={styles.register__form_wrapper}>
                  <Input
                    label={t("Email")}
                    type="email"
                    name="email"
                    placeholder={`${
                      localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                    } ${t("Email")}`}
                    value={email}
                    onChange={(e) => {
                      checkEmail(e.target.value, setEmail, setErrorEmail);
                      setEmailInvite(e.target.value);
                      checkEmail(
                        e.target.value,
                        setEmailInvite,
                        setErrorEmailInvite
                      );
                    }}
                    error={errorEmail}
                  />
                  <Input
                    label={t("Repeat Email")}
                    type="email"
                    name="emailAgain"
                    placeholder={t("Repeat email")}
                    value={emailAgain}
                    onChange={(e) =>
                      checkEmailAgain(
                        e.target.value,
                        email,
                        setEmailAgain,
                        setErrorEmailAgain
                      )
                    }
                    error={errorEmailAgain}
                  />
                </div>
                {!inviteCode &&
                !referalId &&
                !localStorage.getItem("intern") &&
                !localStorage.getItem("inviteRefCode") &&
                !localStorage.getItem("refID") ? (
                  <div className={styles.register__form_referal}>
                    <Input
                      label={t("Tippgeber-ID")}
                      type="text"
                      name="referal"
                      placeholder={`${
                        localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                      } ${t("Referral ID")}`}
                      value={inviteCodeClient.split("/")[1]}
                      onChange={(e) => {
                        console.log(inviteCode);
                        // changeReferral()
                        setInviteCodeClient(
                          e.target.value.split("-")[1] &&
                            e.target.value.split("-")[1].length > 3
                            ? "referral" + "/" + e.target.value
                            : "ref" + "/" + e.target.value
                        );
                      }}
                      disabled={disabledReferalId}
                      // error={errorReferalId}
                      error={t(errorReferalId)}
                    />
                    <div
                      className={styles.register__form_btnRef}
                      onClick={() => setIsModalRefId(true)}
                    >
                      {t("I don't have a referral ID")}
                    </div>
                  </div>
                ) : (
                  !localStorage.getItem("intern") &&
                  (!Number.isInteger(+localStorage.getItem("refID")) ||
                    !localStorage.getItem("refID")) &&
                  !localStorage.getItem("level") && (
                    <>
                      {((inviteCode && inviteCode.length) || referalId) && (
                        <div className={styles.register__form_referal}>
                          <Input
                            label={t("Referral ID")}
                            type="text"
                            name="referal"
                            placeholder={`${
                              localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                            } ${t("Referral ID")}`}
                            value={
                              inviteCode ? inviteCode : referalId.split("/")[1]
                            }
                            onChange={(e) => {
                              localStorage.setItem(
                                "ref",
                                e.target.value.split("-")[1] &&
                                  e.target.value.split("-")[1].length > 3
                                  ? "referral" + "/" + e.target.value
                                  : "ref" + "/" + e.target.value
                              );
                              checkReferalId(
                                e.target.value.split("-")[1] &&
                                  e.target.value.split("-")[1].length > 3
                                  ? "referral" + "/" + e.target.value
                                  : "ref" + "/" + e.target.value,
                                inviteCode,
                                setReferalId,
                                setErrorReferalId
                              );
                            }}
                            disabled={disabledReferalId}
                            // error={errorReferalId}
                            error={t(errorReferalId)}
                          />
                          <div
                            className={styles.register__form_btnRef}
                            onClick={() => setIsModalRefId(true)}
                          >
                            {t("I don't have a referral ID")}
                          </div>
                        </div>
                      )}
                    </>
                  )
                )}
                {/* {console.log(inviteCode.split("/")[1])}
                {console.log(inviteCode.split("/")[1])} */}

                <div className={styles.register__form_wrapper}>
                  <Input
                    label={t("Password")}
                    type="password"
                    name={t("Password")}
                    placeholder="••••••••••"
                    value={password}
                    onChange={(e) =>
                      checkPass(e.target.value, setPassword, setErrorPassword)
                    }
                    error={errorPassword}
                  />
                  <Input
                    label={t("Repeat password")}
                    type="password"
                    name="passwordAgain"
                    placeholder="••••••••••"
                    value={passwordAgain}
                    onChange={(e) =>
                      checkPasswordAgain(
                        e.target.value,
                        password,
                        setPasswordAgain,
                        setErrorPasswordAgain
                      )
                    }
                    error={errorPasswordAgain}
                  />
                </div>
                <div className={styles.register__form_check}>
                  <Checkbox
                    text={
                      localStorage.getItem("i18n") != "EN" ? (
                        <span>
                          Ich habe die{" "}
                          <Link to="/agb" target="_blank">
                            AGB
                          </Link>{" "}
                          gelesen und bin mit deren Geltung einverstanden
                        </span>
                      ) : (
                        <span>
                          I have read the{" "}
                          <Link to="/agb" target="_blank">
                            AGB
                          </Link>{" "}
                          and agree with their validity
                        </span>
                      )
                    }
                    value={age}
                    onChange={(e) =>
                      checkAge(e.target.checked, setAge, setErrorAge)
                    }
                    error={errorAge}
                  />
                </div>
                <div className={styles.register__form_check}>
                  <Checkbox
                    text={
                      localStorage.getItem("i18n") != "EN" ? (
                        <span>
                          Ich habe die{" "}
                          <Link to="/privacy" target="_blank">
                            Datenschutzbestimmungen
                          </Link>{" "}
                          zur Kenntnis genommen und akzeptiere diese
                        </span>
                      ) : (
                        <span>
                          I have taken note of the{" "}
                          <Link to="/privacy" target="_blank">
                            privacy policy
                          </Link>{" "}
                          and accept it
                        </span>
                      )
                    }
                    value={privacy}
                    onChange={(e) =>
                      checkAge(e.target.checked, setPrivacy, setErrorPrivacy)
                    }
                    error={errorPrivacy}
                  />
                </div>
                {typeof saleLvl === "string" || typeof saleLvl === "number" ? (
                  <div className={styles.register__form_check}>
                    <Checkbox
                      text={
                        <span>
                          {t("I have read the ")}
                          <a
                            href={
                              saleLvl == 0
                                ? `${FRONT_URL}/files/Partnervertrag - Deutsche Anbaugesellschaft DAG GmbH.pdf`
                                : `${FRONT_URL}/files/Tippgebervertrag Stufe ${saleLvl} DAG GmbH.pdf`
                            }
                            target="_blanc"
                          >
                            {isSaleZeroLvl
                              ? t("Partner Agreement")
                              : t("affiliate agreement")}
                          </a>{" "}
                          {t("and agree with their validity.")}
                        </span>
                      }
                      value={checkSale}
                      onChange={(e) =>
                        checkAge(
                          e.target.checked,
                          setCheckSale,
                          setErrorSaleCheck
                        )
                      }
                      error={errorSaleCheck}
                    />
                  </div>
                ) : null}
                {roleRegister === "hs" ? (
                  <div className={styles.register__form_check}>
                    <Checkbox
                      text={
                        localStorage.getItem("i18n") != "EN" ? (
                          <span>
                            Ich habe den{" "}
                            <a
                              href={`${FRONT_URL}/files/Partnervertrag - Deutsche Anbaugesellschaft DAG GmbH.pdf`}
                              target="_blanc"
                            >
                              Partnervertrag
                            </a>{" "}
                            gelesen und bin mit deren Geltung einverstanden.
                          </span>
                        ) : (
                          <span>
                            I have read the{" "}
                            <a
                              href={`${FRONT_URL}/files/Partnership_Agreement(head-of-sales).pdf`}
                              target="_blanc"
                            >
                              affiliate agreement
                            </a>{" "}
                            and agree to its validity.
                          </span>
                        )
                      }
                      value={checkHeadOfSale}
                      onChange={(e) =>
                        checkAge(
                          e.target.checked,
                          setCheckHeadOfSale,
                          setErrorHeadOfSalesCheck
                        )
                      }
                      error={errorHeadOfSalesCheck}
                    />
                  </div>
                ) : null}
                <Button
                  text={t("Create Account")}
                  color="green"
                  onClick={signUpHandler}
                />
              </form>
              {serversError && serversError.length ? (
                <div className={styles.register__form_errors}>
                  {serversError.map((item, index) => {
                    return (
                      <div key={index} className={styles.register__form_error}>
                        {[
                          "This email is already taken",
                          "Diese E-Mail ist bereits vergeben",
                        ].includes(item)
                          ? ""
                          : item}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div
              className={`${styles.register__right}`}
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img src={register} alt={t("login")} />
            </div>
            {/* <div className={`${styles.register__right} ${styles.mobile}`}>
              <picture>
                <source srcSet={mokupToken} type="image/webp" />
                <img src={mokupToken} alt="token" />
              </picture>
              <h3>{t("Your benefits:")}</h3>
              <ul>
                <li>{t("Safe & uncomplicated")}</li>
                <li>{t("Profit sharing through planting site")}</li>
                <li>{t("500€ annual yield")}</li>
                <li>{t("Manage & view plants")}</li>
                <li>{t("Payout to the bank account")}</li>
              </ul>
            </div> */}
          </div>
        </div>
        {isModalRefId ? (
          <div className={styles.register__modal}>
            <div className={styles.register__modal_inner}>
              <button
                className={styles.register__modal_close}
                onClick={() => setIsModalRefId(false)}
              >
                <Cross />
              </button>
              <div className={styles.register__modal_title}>
                {t(
                  "Please let us know your email and we will send you the Referral"
                ) + " "}
                {t("ID for registering on the platform")}
              </div>
              <Input
                label={t("email")}
                type="email"
                name="email"
                placeholder={`${
                  localStorage.getItem("i18n") == "DE" ? "Ihre" : ""
                } ${t("Email")}`}
                value={emailInvite}
                onChange={(e) =>
                  checkEmail(
                    e.target.value,
                    setEmailInvite,
                    setErrorEmailInvite
                  )
                }
                error={
                  errorEmailInvite
                    ? errorEmailInvite
                    : emailUsedError
                    ? t("This email is already taken")
                    : ""
                }
              />
              <Button
                text={t("Send ")}
                color="darkGreen"
                disabled={!isAllowSubmitCode}
                onClick={() => sendRequestToReferalID()}
              />
            </div>
          </div>
        ) : null}
        {isEmailCheckRef ? (
          <div className={styles.register__modal}>
            <div
              className={styles.register__modal_inner}
              style={{ maxWidth: "500px" }}
            >
              <section className={styles.check}>
                <img
                  src={confirm}
                  alt={t("confirm")}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
                <h2 className={styles.check__title}>
                  {t("Referal id was sent to your email address.")}
                </h2>
                <div className={styles.check__descr}>
                  {t("Please check you mailbox. ")}
                </div>
                <Button
                  text={t("Close")}
                  color="green"
                  onClick={() => setIsEmailCheckRef(false)}
                />
              </section>
            </div>
          </div>
        ) : null}
        {isEmailCheckRef ? (
          <div className={styles.register__modal}>
            <div
              className={styles.register__modal_inner}
              style={{ maxWidth: "500px" }}
            >
              <section className={styles.check}>
                <img
                  src={confirm}
                  alt={t("confirm")}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
                <h2 className={styles.check__title}>
                  {t("Referral ID was sent to your email address.")}
                </h2>
                <div className={styles.check__descr}>
                  {t("Please check you mailbox.")}
                </div>
                <Button
                  text={t("Close")}
                  color="green"
                  onClick={() => setIsEmailCheckRef(false)}
                />
              </section>
            </div>
          </div>
        ) : null}
        {isValidCode == false ? <UsedCodeModal /> : null}
      </section>
      <FooterCommon />
      {saleRegModal && (
        <ModalRegCompleted
          buttonText={t("Get Started")}
          img={hands}
          id={location?.pathname.replace("/register/", "")}
          text={t(
            "Thank you for registration now you can login with your login and password"
          )}
          onClose={() => setSaleRegModal(false)}
        />
      )}
    </>
  );
};

export default Register;
