/** @format */

import React, { useState } from "react";
import styles from "./New.module.scss";
import { API_URL } from "../../../../http";
import moment from "moment";
import ArrowIcon from "../../../../img/IconComponents/ArrowIcon";
import { useTranslation } from "react-i18next";

import tempImg00 from "../../../../img/temp/00.jpg";
import tempImg01 from "../../../../img/temp/01.jpg";
import tempImg02 from "../../../../img/temp/02.jpg";
import tempImg03 from "../../../../img/temp/03.png";
import tempImg04 from "../../../../img/temp/04.jpg";
import tempImg05 from "../../../../img/temp/05.jpg";

const New = ({ item }) => {
  const { t } = useTranslation();
  const { id, title, content, created_at, image_id } = item;
  const [opened, setOpened] = useState(false);
  if (id === undefined) {
    return (
      <div className={styles.new} key={id}>
        <div className={styles.new__image}>
          <img src={tempImg00} alt={id} />
        </div>
        <div className={styles.new__body}>
          <p className={styles.new__body_date}>17.10.2024</p>
          <div className={styles.new__body_inner}>
            <h3 className={styles.new__body_title}>
              {t("Our “DAG-Grow” cultivation module nearing completion")}
            </h3>
            <div
              className={styles.new__body_desc}
              style={{
                WebkitLineClamp: opened ? 100 : 2,
                maxHeight: opened ? "10000px" : "0px",
              }}
            >
              <img src={tempImg00} alt="img-00" />
              <br />
              <p>
                {t(
                  "Today we would like to inform you about the latest developments at Deutsche Anbaugesellschaft DAG GmbH. The first “DAG-Grow” cultivation module is nearing completion in our 640 m² showroom. We have converted a separate hall and set up the module in it so that cannabis clubs can view the module directly on site and spend time in the hall."
                )}
              </p>
              <br />
              <p>
                {t(
                  "With the “DAG-Grow” cultivation module, we have developed a unique solution for small-scale and efficient cannabis cultivation. Covering an area of 250 m², the module comprises six perfectly coordinated rooms: a cultivation room, drying room, technical room, changing room, disinfection sluice and a room for processing and packaging. All work steps, from cultivation to packaging of the end product, are covered within the module. After more than nine months of intensive planning, a solution was created that fully meets the special requirements of cannabis clubs."
                )}
              </p>
              <br />
              <div className={styles.tempNew__images}>
                <img src={tempImg01} alt="img-01" />
                <img src={tempImg02} alt="img-02" />
              </div>
              <br />
              <p>
                {t(
                  "The cultivation module uses a vertical farming system with two levels to make optimum use of the available space. State-of-the-art technologies ensure an efficient and sustainable production process. A specially developed climate control system ensures the ideal temperature and humidity for cultivation, while air purification systems minimize the risk of infection."
                )}
              </p>
              <br />
              <div className={styles.tempNew__floatLeft}>
                <img src={tempImg03} alt="img-03" />
                <p>
                  {t(
                    "For the lighting, 780 watt LED lamps from the DAG-Grow brand are used, which are adapted to the different growth phases of the plants. These lamps offer a broad light spectrum that also includes ultraviolet and infrared radiation. This ensures that the plants receive the right quality of light in every growth phase."
                  )}
                </p>
              </div>
              <br />
              <p>
                {t(
                  "A fully automated high-tech system specially developed for cannabis cultivation is used for optimum irrigation and nutrient supply. All parameters can be precisely set and controlled via a central control unit. The plants need a lot of water and an optimal supply of nutrients is crucial for the plants' growth."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The plants are also supplied with CO2. The use of CO2 (carbon dioxide) is crucial to optimize the growth of cannabis plants, especially under powerful lamps. Enriching the air with CO2 can significantly increase the photosynthesis rate of the plants, resulting in faster growth and higher yields."
                )}
              </p>
              <br />
              <p>
                {t(
                  "All parameters such as the climate, lighting, irrigation, nutrient supply and CO2 supply can be centrally controlled and monitored via our “DECT-Grow” cultivation management system. This is also possible via a tablet or smartphone."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The DAG cultivation module provides the complete infrastructure for cannabis cultivation, including a secure energy supply. The enormous amount of energy required for indoor cannabis cultivation is often underestimated. This already requires a high connected load, which is not available in many buildings."
                )}
              </p>
              <br />
              <p className={styles.tempNew__floatRight}>
                <img src={tempImg04} alt="img-04" />
                {t(
                  "The module has also been comprehensively equipped in terms of security: it has an electronic security system, video surveillance and access controls that are reserved exclusively for members of the growers' associations. The entire site is also monitored around the clock and access is exclusively via a guarded driveway."
                )}
                <br />
                <br />
                {t(
                  "A head grower provides training and further education at the site. He is available around the clock. Later, there will also be networking events and industry meetings."
                )}
                <br />
                <br />
                {t(
                  "With the DAG cultivation module, cannabis clubs can start growing immediately without any major investment or know-how. The module makes it possible to grow up to 300 kg of high-quality cannabis to supply up to 500 members per club."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The completion of the first add-on module will be announced shortly and presented at a press conference with political representatives. We are already in contact with several hundred interested clubs."
                )}
              </p>
              <br />
              <p>
                {t(
                  "The completion of the first add-on module will be announced shortly and presented at a press conference with political representatives. We are already in contact with several hundred interested clubs."
                )}
              </p>
              <br />
              <img src={tempImg05} alt="img-05" />
            </div>
            <div
              className={styles.new__body_link}
              onClick={() => setOpened((prev) => !prev)}
            >
              {opened ? t("Hide Details") : t("View Details")}{" "}
              <ArrowIcon color="#509c5b" rotate={opened ? "-90deg" : "90deg"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.new} key={id}>
      <div className={styles.new__image}>
        <img src={`${API_URL}/v1/local-files/${image_id}`} alt={id} />
      </div>
      <div className={styles.new__body}>
        <p className={styles.new__body_date}>
          {moment(created_at).format("DD.MM.YYYY")}
        </p>
        <div className={styles.new__body_inner}>
          <h3
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className={styles.new__body_title}
          />
          <p
            className={styles.new__body_desc}
            style={{
              WebkitLineClamp: opened ? 100 : 2,
              maxHeight: opened ? "10000px" : "0px",
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div
            className={styles.new__body_link}
            onClick={() => setOpened((prev) => !prev)}
          >
            {opened ? t("Hide Details") : t("View Details")}{" "}
            <ArrowIcon color="#509c5b" rotate={opened ? "-90deg" : "90deg"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
