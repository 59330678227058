import React from "react";
import styles from "./SingleNews.module.scss";
import HeaderCommon from "../../components/HeaderCommon/HeaderCommon";
import FooterCommon from "../../components/FooterCommon/FooterCommon";
import Button from "../../components/Button/Button";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import getRequest from "../../helpers/getRequest";
import tempImg00 from "../../img/temp/00.jpg";
import tempImg01 from "../../img/temp/01.jpg";
import tempImg02 from "../../img/temp/02.jpg";
import tempImg03 from "../../img/temp/03.png";
import tempImg04 from "../../img/temp/04.jpg";
import tempImg05 from "../../img/temp/05.jpg";

const SingleNews = ({ currentLang, setCurrentLang }) => {
  const [currentNew, setCurrentNew] = useState({});
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const PAGE = 1;
  const PER_PAGE = 3;
  useEffect(() => {
    try {
      getRequest(
        `v1/articles/?page=${PAGE}&perPage=${PER_PAGE}`,
        null,
        currentLang.lang
      ).then(async (response) => {
        const data = await response.data;
        // console.log(data.articles);
        setNews(data.articles);
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentLang, id]);

  useEffect(() => {
    try {
      getRequest(`v1/articles/${id}`, null, currentLang.lang).then(
        async (response) => {
          const data = await response.data;
          // console.log("CURRENT NEW\n", data.article[0].image);
          setCurrentNew(data.article[0]);
        }
      );
    } catch (err) {
      console.log(err);
    }
    console.log(currentLang);
  }, [id, currentLang]);
  return (
    <>
      <HeaderCommon currentLang={currentLang} setCurrentLang={setCurrentLang} />
      <section className={styles.new}>
        <div className="container">
          <div className={styles.new__inner}>
            {id == "00" ? (
              <div className={styles.new__body}>
                <span>
                  {currentLang.lang == "EN"
                    ? "October 17, 2024"
                    : "17. Oktober 2024"}
                </span>
                <h1>
                  {t("Our “DAG-Grow” cultivation module nearing completion")}
                </h1>
                <div className={styles.tempNew}>
                  <img src={tempImg00} alt="img-00" />
                  <p>
                    {t(
                      "Today we would like to inform you about the latest developments at Deutsche Anbaugesellschaft DAG GmbH. The first “DAG-Grow” cultivation module is nearing completion in our 640 m² showroom. We have converted a separate hall and set up the module in it so that cannabis clubs can view the module directly on site and spend time in the hall."
                    )}
                  </p>
                  <p>
                    {t(
                      "With the “DAG-Grow” cultivation module, we have developed a unique solution for small-scale and efficient cannabis cultivation. Covering an area of 250 m², the module comprises six perfectly coordinated rooms: a cultivation room, drying room, technical room, changing room, disinfection sluice and a room for processing and packaging. All work steps, from cultivation to packaging of the end product, are covered within the module. After more than nine months of intensive planning, a solution was created that fully meets the special requirements of cannabis clubs."
                    )}
                  </p>
                  <div className={styles.tempNew__images}>
                    <img src={tempImg01} alt="img-01" />
                    <img src={tempImg02} alt="img-02" />
                  </div>
                  <p>
                    {t(
                      "The cultivation module uses a vertical farming system with two levels to make optimum use of the available space. State-of-the-art technologies ensure an efficient and sustainable production process. A specially developed climate control system ensures the ideal temperature and humidity for cultivation, while air purification systems minimize the risk of infection."
                    )}
                  </p>
                  <div className={styles.tempNew__floatLeft}>
                    <img src={tempImg03} alt="img-03" />
                    <p>
                      {t(
                        "For the lighting, 780 watt LED lamps from the DAG-Grow brand are used, which are adapted to the different growth phases of the plants. These lamps offer a broad light spectrum that also includes ultraviolet and infrared radiation. This ensures that the plants receive the right quality of light in every growth phase."
                      )}
                    </p>
                  </div>
                  <p>
                    {t(
                      "A fully automated high-tech system specially developed for cannabis cultivation is used for optimum irrigation and nutrient supply. All parameters can be precisely set and controlled via a central control unit. The plants need a lot of water and an optimal supply of nutrients is crucial for the plants' growth."
                    )}
                  </p>
                  <p>
                    {t(
                      "The plants are also supplied with CO2. The use of CO2 (carbon dioxide) is crucial to optimize the growth of cannabis plants, especially under powerful lamps. Enriching the air with CO2 can significantly increase the photosynthesis rate of the plants, resulting in faster growth and higher yields."
                    )}
                  </p>
                  <p>
                    {t(
                      "All parameters such as the climate, lighting, irrigation, nutrient supply and CO2 supply can be centrally controlled and monitored via our “DECT-Grow” cultivation management system. This is also possible via a tablet or smartphone."
                    )}
                  </p>
                  <p>
                    {t(
                      "The DAG cultivation module provides the complete infrastructure for cannabis cultivation, including a secure energy supply. The enormous amount of energy required for indoor cannabis cultivation is often underestimated. This already requires a high connected load, which is not available in many buildings."
                    )}
                  </p>
                  <p className={styles.tempNew__floatRight}>
                    <img src={tempImg04} alt="img-04" />
                    {t(
                      "The module has also been comprehensively equipped in terms of security: it has an electronic security system, video surveillance and access controls that are reserved exclusively for members of the growers' associations. The entire site is also monitored around the clock and access is exclusively via a guarded driveway."
                    )}
                    <br />
                    <br />
                    {t(
                      "A head grower provides training and further education at the site. He is available around the clock. Later, there will also be networking events and industry meetings."
                    )}
                    <br />
                    <br />
                    {t(
                      "With the DAG cultivation module, cannabis clubs can start growing immediately without any major investment or know-how. The module makes it possible to grow up to 300 kg of high-quality cannabis to supply up to 500 members per club."
                    )}
                  </p>
                  <p>
                    {t(
                      "The completion of the first add-on module will be announced shortly and presented at a press conference with political representatives. We are already in contact with several hundred interested clubs."
                    )}
                  </p>
                  <img src={tempImg05} alt="img-05" />
                </div>
                <Button text={t("Back")} onClick={() => navigate(-1)} />
              </div>
            ) : (
              <div className={styles.new__body}>
                {currentNew.header_image ? (
                  <img src={currentNew.header_image} alt={currentNew.title} />
                ) : (
                  ""
                )}
                <span>
                  {currentLang.lang == "EN"
                    ? new Date(currentNew.created_at).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )
                    : new Date(currentNew.created_at).toLocaleDateString(
                        "de-DE",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                </span>
                <h1 dangerouslySetInnerHTML={{ __html: currentNew.title }} />
                <p dangerouslySetInnerHTML={{ __html: currentNew.content }} />
                <Button text={t("Back")} onClick={() => navigate(-1)} />
              </div>
            )}

            <div className={styles.new__aside}>
              <h3>{t("Recent Posts")}</h3>
              <div>
                {news &&
                  news.length !== 0 &&
                  news.map((n) => (
                    <div className={styles.card} key={n.id}>
                      <a href={`/news/${n.id}`}>
                        <div
                          className={styles.card__main}
                          style={{ backgroundImage: `url(${n.image})` }}
                        ></div>
                        <div className={styles.card__overlay}>
                          <h5>{n.title}</h5>
                          <span>
                            {currentLang.lang == "EN"
                              ? new Date(n.created_at).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )
                              : new Date(n.created_at).toLocaleDateString(
                                  "de-DE",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterCommon />
    </>
  );
};

export default SingleNews;
